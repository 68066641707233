<template>
    <div class="relative" :style="{ height: (this.height || 400) + 'px' }">
        <div v-if="haveData" class="height_100_percent bar" :ref="className"></div>
        <div v-else class="pages flex align_center justify_center">暂无数据</div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import theme from './roma';

echarts.registerTheme('roma', theme.roma);

export default {
    name: 'bar',
    props: ['height', 'chartData', 'className', 'yName'],
    data() {
        return {
            pie: [],
            myChart: null,
        };
    },
    computed: {
        haveData() {
            return (this.chartData || []).length > 0;
        },
    },
    mounted() {
        this.drawChart();
    },
    methods: {
        resize() {
            this.myChart && this.myChart.resize();
        },
        drawChart() {
            if (!this.haveData) {
                return;
            }
            this.$nextTick(() => {
                this.myChart = echarts.init(this.$refs[this.className], 'roma');
                window.onresize = () => {
                    this.resize();
                };
                const resizeObserver = new ResizeObserver(entries => {
                    this.resize();
                });
                resizeObserver.observe(this.$parent.$el);

                let data = this.chartData;
                let xAxisData = [],
                    seriesData = [];
                data.forEach(info => {
                    xAxisData.push(info.name);
                    seriesData.push(info.value);
                });

                // 指定图表的配置项和数据
                var option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985',
                            },
                        },
                    },
                    xAxis: {
                        data: xAxisData,
                        axisLabel: {
                            // interval: 0,
                            // rotate: 20,
                            fontSize: 10,
                        },
                        axisTick: {
                            alignWithLabel: true,
                        },
                    },
                    yAxis: {
                        name: this.yName || '',
                        axisLine: {
                            show: true,
                        },
                        axisTick: {
                            show: true,
                        },
                        axisLabel: {
                            color: '#999',
                        },
                    },
                    series: [
                        {
                            type: 'bar',
                            showBackground: true,
                            barMaxWidth: 40,
                            sampling: 'lttb', //lttb average max min sum
                            emphasis: {
                                itemStyle: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        { offset: 0, color: '#2378f7' },
                                        { offset: 0.7, color: '#2378f7' },
                                        { offset: 1, color: '#83bff6' },
                                    ]),
                                },
                            },
                            data: seriesData,
                        },
                    ],
                };
                this.myChart.setOption(option);
            });
        },
    },
    watch: {
        chartData: {
            deep: true,
            handler() {
                console.log('watch chartData...');
                this.drawChart();
            },
        },
    },
};
</script>

<style scoped lang="less">
.bar {
    margin: 0 auto;
    width: 100%;
}
</style>
